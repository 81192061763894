import Vue from 'vue';
import { validExistFile } from '../mixins/files.Mixin';

const {
  API_CHANNEL_NAME: apiChannelName,
  API_CHANNEL_THUMBNAIL: apiChannelThumbnail,
  APP_VERSION: appVersion,
  AZURE_APP_ID: azureAppId,
  BRAND_NAME: brandName,
  CHATWOOT_INBOX_TOKEN: chatwootInboxToken,
  CSML_EDITOR_HOST: csmlEditorHost,
  CREATE_NEW_ACCOUNT_FROM_DASHBOARD: createNewAccountFromDashboard,
  DIRECT_UPLOADS_ENABLED: directUploadsEnabled,
  DISPLAY_MANIFEST: displayManifest,
  GIT_SHA: gitSha,
  HCAPTCHA_SITE_KEY: hCaptchaSiteKey,
  INSTALLATION_NAME: installationName,
  LOGO_THUMBNAIL: logoThumbnail,
  LOGO: logo,
  LOGO_DARK: logoDark,
  PRIVACY_URL: privacyURL,
  TERMS_URL: termsURL,
  WIDGET_BRAND_URL: widgetBrandURL,
  DISABLE_USER_PROFILE_UPDATE: disableUserProfileUpdate,
  DEPLOYMENT_ENV: deploymentEnv,
  SUBDOMAIN: subdomain,
} = window.globalConfig || {};

const getSubdomain = () => {
  const domain = window.location.host;
  const result = domain.split('.')[0];
  if (result === 'chat') return null;
  return result;
};

const domain = getSubdomain();
const isSubdomain = !!domain;

let nubla_installationName = installationName;
let nubla_brandName = brandName;
let nubla_logo = logo;
let nubla_logoDark = logoDark;
let nubla_logoThumbnail = logoThumbnail;

const logoSubDomain = () => {
  return `/${subdomain.subdomain}/logo.png`;
};

if (isSubdomain && subdomain) {
  const { name, installation_name } = subdomain;
  const logoSub = logoSubDomain();
  nubla_installationName = installation_name; // getName();
  nubla_brandName = name;
  nubla_logo = logoSub;
  nubla_logoDark = logoSub;
  nubla_logoThumbnail = logoSub;
}

const versionNubla = '62';

const state = {
  apiChannelName,
  apiChannelThumbnail,
  appVersion,
  versionNubla,
  azureAppId,
  brandName: nubla_brandName,
  chatwootInboxToken,
  csmlEditorHost,
  deploymentEnv,
  createNewAccountFromDashboard,
  directUploadsEnabled: directUploadsEnabled === 'true',
  disableUserProfileUpdate: disableUserProfileUpdate === 'true',
  displayManifest,
  gitSha,
  hCaptchaSiteKey,
  installationName: nubla_installationName,
  logo: nubla_logo,
  logoDark: nubla_logoDark,
  logoThumbnail: nubla_logoThumbnail,
  privacyURL,
  termsURL,
  widgetBrandURL,
};

export const getters = {
  get: $state => {
    return $state;
  },
  isOnChatwootCloud: $state => $state.deploymentEnv === 'cloud',
  isACustomBrandedInstance: $state => $state.installationName !== 'Chatwoot',
  isAChatwootInstance: $state => $state.installationName === 'Chatwoot',
};

export const nublaVersion = versionNubla;

export const actions = {
  getSubdomain: async ({ commit, _state }) => {
    if (subdomain) {
      const urlLogo = `/${subdomain.subdomain}/logo.png`;
      const exitLogoSub = await validExistFile(urlLogo);
      if (!exitLogoSub) {
        commit('SET_CONFIGS', { ..._state, logo, logoDark, logoThumbnail });
      }
    }
  },
  addData: ({ commit }, { data }) => {
    commit('ADD_DATA', data);
  },
};

export const mutations = {
  SET_CONFIGS: ($state, data) => {
    Vue.set($state, 'logo', data.logo);
    Vue.set($state, 'logoDark', data.logoDark);
    Vue.set($state, 'logoThumbnail', data.logoThumbnail);
  },
  ADD_DATA: ($state, data) => {
    // for (const key in data) {
    //   if (Object.hasOwnProperty.call(data, key)) {
    //     const newConfig = data[key];
    //     window.globalConfig[key] = newConfig;
    //     Vue.set($state, key, newConfig);
    //   }
    // }
    Object.keys(data).forEach(key => {
      if (Object.hasOwnProperty.call(data, key)) {
        const newConfig = data[key];
        window.globalConfig[key] = newConfig;
        Vue.set($state, key, newConfig);
      }
    });
  },
};

export default {
  getSubdomain,
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
